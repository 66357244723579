@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

html {
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
}

h2 {
  font-size: 2rem;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  margin: 0;
}

h4 {
  font-size: 1.25rem;
  margin: 0;
}

h5 {
  font-size: 1rem;
  margin: 0;
}

h6 {
  font-size: 0.875rem;
  margin: 0;
}

p {
  font-size: 1rem;
  text-align: justify;
  margin: 0;
  word-spacing: 0.05em;
}



a {
  font-size: 1rem;
  text-decoration: none;
}

li {
  font-size: 1rem;
  list-style: none;
}


* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


:root {
  --main-color: #292219;
  --sec-color: #998D7D;
  --third-color: #D8CFBF;

}


/* General Styles */
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  line-height: 1.6;
  color: #000;
  overflow-x: hidden;
  font-family: "Tajawal", sans-serif;
}





.container {
  width: 80%;
  margin: 0 auto;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 74px;
  z-index: 999999;
}

header nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}


header nav .container .nav-list {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-left: auto;

}

html[lang="en"] header nav .container .nav-list {
  margin-left: 0;
  margin-right: auto;
}

header nav .container a {
  font-size: 1.3rem;
  color: #fff;
}


header nav .container .logo {
  width: 60px;
}

header nav .container .logo img {
  width: 100%;

}

header nav .container .icons {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #fff;
}

header nav .container .icons i {
  font-size: 1.2rem;
}

header nav .container .icons button {
  all: unset;
  font-size: 1.3rem;
}

header nav .container a,
header nav .container i,
header nav .container button {
  transition: 0.3s;
}

header nav .container a:hover,
header nav .container i:hover,
header nav .container button:hover {
  color: var(--third-color);
}

/* Common Styles for Menu */
header .menu-item {
  display: none;
}

header .menu-item .menu-icon {
  cursor: pointer;
}

/* Dropdown Menu Styles - Fullscreen */
body header nav .dropdown-menu {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: -20px;
  background-color: var(--third-color);
  /* Light beige background */
  color: var(--main-color);
  /* Dark brown text */
  width: 100%;
  height: calc(100vh + 20px);
  text-align: center;
  padding: 20px 0;
  z-index: 999;
}

body header nav .dropdown-menu.open {
  transform: scaleX(1);
  /* Show the menu */
}

/* Close Button */
body header nav .dropdown-menu .close-btn {
  color: var(--main-color);
  position: absolute;
  top: 20px;
  font-size: 24px;
  cursor: pointer;
  transition: 0.3s;
}

body header nav .dropdown-menu .close-btn i {
  font-size: 1.5rem;
}

body header nav .dropdown-menu .close-btn:hover i,
body header nav .dropdown-menu a:hover i {
  color: var(--main-color);
  transform: scale(1.2);
}

/* Dropdown Items */

body header nav .dropdown-menu a {
  color: var(--main-color);
  /* Dark brown */
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  font-size: 1.5rem;
  transition: 0.3s;
}

body header nav .dropdown-menu li {
  transition: 0.3s;
}

body header nav .dropdown-menu li:hover {
  background-color: var(--sec-color);
  /* Medium brown on hover */
}

/* Language Switcher */
body header nav .dropdown-menu .language-switcher button {
  background-color: var(--main-color);
  color: var(--third-color);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

body header nav .dropdown-menu .language-switcher button:hover {
  background-color: var(--sec-color);
}

/* RTL vs LTR Adjustments */

/* LTR (Default) */
html[dir="ltr"] header nav .dropdown-menu {
  transform-origin: left;
  /* Menu opens from the left */
}

html[dir="ltr"] header nav .dropdown-menu .close-btn {
  right: 20px;
  /* Close button on the right side */
}

html[dir="ltr"] body header nav .dropdown-menu a {
  text-align: left;
  /* Align text to the left */
}

/* RTL (Arabic) */
html[dir="rtl"] header nav .dropdown-menu {
  transform-origin: right;
  /* Menu opens from the right */
}

html[dir="rtl"] header nav .dropdown-menu .close-btn {
  left: 20px;
  /* Close button on the left side */
}

html[dir="rtl"] body header nav .dropdown-menu a {
  text-align: right;
  /* Align text to the right */
}

section {
  padding: 120px 0;
}

section#main {
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(./assets/bgImage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

section#main h3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-inline: 5%;
  font-size: 3.5rem;
  color: #fff;
  font-weight: 400;
}

section#main .modal-btn {
  position: absolute;
  bottom: 160px;
  transform: translateX(-50%);
  left: 50%;
  background-color: #fff;
  color: var(--sec-color);
}

section#main .modal-btn:hover {
  background-color: var(--sec-color);
  color: #fff;
}



section#main .bg-video,

section#main .bg-image {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

section#main::after {
  position: absolute;
  content: "";
  height: 80px;
  width: 2px;
  bottom: 100px;
  left: 50%;
  background-color: #fff;
  transform: translateX(-50%);
}

section#main .scroll-text {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  color: #fff;
  filter: brightness(1.5);
  z-index: 9999;
}

section#about .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section#about .container .text {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  gap:
    30px;
}

section#about .container .text .heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

section#about .container .text .heading h3 {
  position: relative;
  font-size: 1.2rem;
  font-weight: normal;
}

section#about .container .text .heading span {
  display: inline-block;
  width: 40px;
  height: 1px;
  background-color: var(--main-color);
}


section#about .container .text h4 {
  font-size: 1.6rem;
}

section#about .container .text p {
  font-size: 1.4rem;
}


section#about .container .image {
  flex-basis: 40%;
}

section#about .container .image img {
  width: 100%;
  height: 60%;
}


section#about .info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

section#about .info-container .info {
  display: flex;
  flex-direction: column-reverse;

  align-items: center;
  text-align: center;
  color: var(--third-color);
}


section#about .info-container .info h3 {
  color: #000;
  font-size: 1.3rem;
  font-weight: 400;
}

section#about .info-container .info span {
  color: var(--sec-color);
  font-weight: 900;
  font-size: 3rem;
}

section#brief {
  position: relative;
  background-color: var(--main-color);
  color: var(--third-color);
  padding: 120px 0 280px 0;
}

section#brief .cutter {
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  background-color: #fff;
  height: 200px;
  width: 100%;

  position: absolute;
  bottom: -1px;
}




section#brief .bg-img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}

section#brief .bg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section#brief .container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 120px;
}


section#brief .container>div h3 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}

section#brief .container>div p {
  font-size: 1.7rem;
}
section#brief .container>div p {
  text-align: justify;
}


section#info .container .text p {
  text-align: justify;
  font-size: 2rem;
  font-weight: 400;
  width: 65%;
  margin: 0 auto;
}

section#info {
  position: relative;
}

section#info .container .features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* Responsive columns */
  gap: 20px;
  /* Space between grid items */
  padding: 20px;
  margin-top: 40px;
}

section#info .container .features-container .feature {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
}


section#info .container .features-container .feature h4 {
  font-size: 2rem;
  font-weight: 500;
}

section#info .container .features-container .feature img {
  width: 100px;
}


section#info .container .features-container .feature p {
  width: 80%;
}

section#info .bg-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px;
}

section#info .bg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section#info .container .title {
  font-size: 3rem;
  text-align: center;
  color: var(--sec-color);
}

section#partners .cutter {
  clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0% 100%, 0% 0%, 50% 20%);
  background-color: var(--main-color);
  width: 100%;
  height: 500px;
}

section#partners {
  padding: 80px 0 0 0;
  background-color: #fff;
}

#partners {
  overflow: hidden;
  /* Hide overflowing images */
  position: relative;
  /* Position relative for absolute child positioning */

}



#partners .container {
  position: relative;
  width: 100%;
  height: 45vh;
}


#partners .container h3 {
  text-align: center;
  margin-top: 60px;
  font-size: 3rem;
  color: var(--sec-color);
}


#partners .img-container {
  display: flex;
  /* Use flex to align items */
  position: relative;
  /* Position relative for positioning child images */
  height: 100%;
  /* Match the height of the parent */
  margin: 100px 0 180px 0;
}

#partners .image {
  position: absolute;
  /* Use absolute to allow free positioning */
  top: 0;
  /* Align top */
  left: 0;
  width: 240px;
  /* Standardized width */
  height: 160px;
  /* Standardized height */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  /* Background color for contrast */
  margin: 0 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
}

#partners .image img,
#partners .image svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  

}


.cutter.page {
  clip-path: polygon(100% 0%, 52.25% 0%, 0% 0%, 50% 100%);
  background-color: #000000;
  background-color: var(--main-color);
  width: 100%;
  height: 200px;
  margin-bottom: auto;
  margin-top: -1px;
}


#projects {
  padding: 0 0 80px 0;
}


.projects-slider {
  padding: 0 40px;
}

.project-box {
  background-color: #fff;
  color: var(--sec-color);
  transition: 0.3s;
  padding: 0 20px;
  cursor: pointer;
  width: 420px;
  margin: 0 auto;
}


#projects .container>.project-box .options {
  flex-direction: row-reverse;
}

.project-box .image {
  width: 100%;
  height: 320px;
}

.project-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}

.project-box:hover img {
  filter: brightness(0.8);
}

.project-box h4 {
  margin-top: 10px;
  font-size: 2rem;
}

.project-box h5 {
  font-size: 1.5rem;
  font-weight: normal;

  text-align: right;
}

.project-box .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

html[lang="en"] .project-box .options {
  flex-direction: row-reverse;
}


html[lang="en"] .project-box .options h5 {
  text-align: left;
}

.project-box .options a {
  color: var(--main-color);
  margin-top: auto;
  background-color: var(--sec-color);
  border: 1px solid var(--sec-color);
  transition: 0.3s;
  position: relative;
  width: 120px;
  height: 40px;
  box-sizing: border-box;

}

.project-box .options a span {
  position: absolute;
  text-align: center;
  width: 120px;
  height: 40px;
  font-size: 1.2rem;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: 0.3s;
  line-height: 40px;

}
.project-box .options a:hover {
  color: var(--sec-color);
  background-color: #fff;
}

.project-box .options a:hover span {
  color: var(--sec-color);
}

.project-images {
  position: relative;
  height: 100vh;
}

.project-images img {
  height: 100vh;
  width: 100%;

}

.project-images .project-info {
  position: absolute;
  z-index: 999;
  margin: 0 40px;
  top: 40%;
  transform: translateY(-50%);
  color: #fff;
}

.project-images .project-info h3 {
  font-size: 4rem;
}

.project-images .project-info h4 {
  font-size: 2rem;
}

.project-details {
  padding: 40px 0;
  color: var(--sec-color);
}


.project-details .title {
  font-size: 2.5rem;
}

.project-details .project-brief {
  font-size: 1.35rem;
  width: 60%;
  color: #000;
}

.project-details .info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.project-details .info-container .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 280px;

}

.project-details .info-container .info:nth-of-type(2) {
  margin: 0 auto;
}

.project-details .info-container .info span {
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
}

.project-details .info-container .info h3 {
  font-size: 2rem;
  text-align: center;
  color: #000;
  font-weight: normal;
}


#map {
  position: relative;
  padding: 0;
  background-color: var(--main-color);
}

#map img.logo {
  width: 80px;
  margin: 20px;
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 999;
}

#map .map-container {
  margin: 0 auto;

}

.region-popUp {
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}


footer {
  background-color: var(--main-color);
  color: var(--third-color);
  border-top: 1px solid var(--sec-color);
  margin-top: 60px;
}

footer .footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  width: 95%;
}



footer .footer-container h3 {
  color: var(--third-color);
  transition: 0.3s;
  cursor: pointer;
  font-size: 1.7rem;
}

footer .footer-container h3:hover {
  color: var(--sec-color);
}

footer .footer-container li a {
  color: var(--third-color);
  font-size: 1.5rem;
  transition: 0.3s;
}

footer .footer-container .logo {
  width: 100px;
  margin-bottom: 20px;
}


footer .footer-container li a:hover {
  color: var(--sec-color);
}

footer .footer-container ul {
  padding: 0;
}

footer .footer-container .about-list {
  display: flex;
  flex-direction: column;
}

footer .footer-container .about-list p {
  font-size: 1.4rem;
}

html[lang="en"] footer .footer-container .about-list p {
  text-align: unset;
}

footer .footer-container .contact-list {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

footer .footer-container .contact-list li {
  position: relative;
  background-color: var(--third-color);
  color: var(--main-color);
  border: 1px solid var(--third-color);
  width: 200px;
  height: 40px;
  cursor: pointer;
  display: flex;
  /* Added for flexbox */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

footer .footer-container .contact-list li span {
  font-size: 1.2rem;
  font-weight: bold;
  transition: 0.3s;
  line-height: 0;

}

footer .footer-container .contact-list li:hover {
  background-color: transparent;
  border-color: var(--third-color);

}

footer .footer-container .contact-list li:hover span {
  color: var(--third-color);
}


footer .footer-rights {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 10px 20px;
  border-top: 5px solid var(--third-color);
  width: 100%;
  ;
}

footer .footer-rights .icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

footer .footer-rights .icons a {
  background-color: var(--third-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.3s;
}

footer .footer-rights .icons a:hover {
  border-color: var(--third-color);

  background-color: transparent;
}

footer .footer-rights .icons a i {
  color: var(--main-color);
  font-size: 1rem;
  transition: 0.3s;
}

footer .footer-rights .icons a:hover i {
  color: var(--third-color);
}



.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 20px;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-top: 8px solid var(--main-color);
  /* Change to your theme color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  text-align: center;
  padding: 20px;
  color: #ff3b3b;
  /* Error color */
}

.error-message h2 {
  margin-bottom: 10px;
}

.error-message button {
  padding: 10px 20px;
  background-color: #3498db;
  /* Change to your theme color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #2980b9;
  /* Change to your theme color */
}

.slick-dots {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.leaflet-control-attribution {
  display: none;
}

.custom-marker .marker-content {
  background-color: var(--main-color);
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #82735E;
}

/* Custom control styles */
.leaflet-control-layers {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.leaflet-control-layers a {
  color: #3498db;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 10px;
}

.leaflet-control-layers-base label {
  font-size: 14px;
  padding: 6px;
  display: block;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.leaflet-control-layers-expanded {
  padding: 10px;
}

.leaflet-popup-content h2 {
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 5px;
}

.leaflet-popup-content p {
  font-size: 14px;
  color: #34495e;
}

.custom-marker .marker-content {
  background-color: var(--main-color);
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
}

/* Layer Toggle Styles */
.layer-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.layer-toggle button {
  padding: 8px 12px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: var(--third-color);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.layer-toggle button.active {
  background-color: var(--main-color);
  /* Different color for active state */
}

.layer-toggle button:hover {
  background-color: var(--sec-color);
}

/* Back Button Styles */
.back-btn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  font-size: 16px;
  padding: 10px 15px;
  background-color: var(--main-color);
  color: white;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.back-btn i {
  margin-right: 8px;
}

.back-btn:hover {
  background-color: var(--sec-color);
}

/* Modal Styles with Specific Selectors */
.form-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  /* Initially hidden */
  visibility: hidden;
  /* Prevents interaction when hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  /* Smooth transitions */
}

.form-modal-open {
  opacity: 1;
  /* Fully visible */
  visibility: visible;
  /* Allows interaction */
}

.form-modal-content {
  background-color: white;
  padding: 30px;
  /* Increased padding for a spacious feel */
  border-radius: 10px;
  /* Rounded corners */
  width: 90%;
  max-width: 500px;
  /* Responsive max width */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease-out;
  /* Slide-in effect */
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.modal-btn {
  margin: 40px auto;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sec-color);
  width: fit-content;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid var(--sec-color);
}

.modal-btn:hover {
  background-color: transparent;
  color: var(--sec-color);
}


.form-modal-close {
  cursor: pointer;
  font-size: 28px;
  /* Larger close button for accessibility */
  color: var(--main-color);
  position: absolute;
  right: 15px;
  /* Default for LTR */
  top: 15px;
  /* Positioning to the top right */
}

.form-modal-close[dir="rtl"] {
  left: 15px;
  /* Change position for RTL */
  right: auto;
  /* Reset right */
}

.form-modal h2 {
  margin-top: 0;
  text-align: center;
  /* Center title */
  color: var(--main-color);
  /* Change color for better visibility */
}

.form-modal label {
  display: block;
  margin-bottom: 8px;
  /* Spacing for labels */
  font-weight: bold;
  /* Bold labels for better readability */
}

.form-modal input,
.form-modal textarea {
  width: 100%;
  padding: 10px;
  /* More padding for inputs */
  margin-top: 5px;
  border: 1px solid var(--sec-color);
  border-radius: 4px;
  transition: border-color 0.3s;
  /* Transition for border color on focus */
}

.form-modal input:focus,
.form-modal textarea:focus {
  border-color: var(--main-color);
  /* Highlight on focus */
}

.form-modal button {
  background-color: var(--main-color);
  color: white;
  padding: 12px 20px;
  /* Adjusted padding for button */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  /* Full width button */
  font-weight: bold;
  /* Bold button text */
  transition: background-color 0.3s;
  /* Transition for hover */
}

.form-modal button:hover {
  background-color: var(--sec-color);
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 40px;
  color: white;
  cursor: pointer;
  z-index: 1;
  user-select: none;
}

.next-arrow {
  right: 10px;
}

.prev-arrow {
  left: 10px;
}

.rtl .next-arrow {
  left: 10px;
}

.rtl .prev-arrow {
  right: 10px;
}

/* Custom arrow styling for the projects slider */
#projects .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: var(--main-color);
  /* Use the main color for arrows */
  font-size: 3rem;
  /* Adjust the size as needed */
  cursor: pointer;
}

/* Positioning for the previous arrow */
#projects .prev-arrow {
  left: -10px;
  /* Adjust as needed for spacing */
}

/* Positioning for the next arrow */
#projects .next-arrow {
  right: -10px;
  /* Adjust as needed for spacing */
}

/* Hover effect for arrows */
#projects .custom-arrow:hover {
  color: var(--sec-color);
  /* Darker color on hover */
}

/* Slick slider dots styling */
#projects .slick-dots li button:before {
  color: var(--main-color);
  /* Dot color */
  font-size: 12px;
}

/* Active dot styling */
#projects .slick-dots li.slick-active button:before {
  color: var(--sec-color);
  /* Active dot color */
}


