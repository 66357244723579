:root {
  --main-color: #292219;
  --sec-color: #998D7D;
  --third-color: #D8CFBF;

}





@media screen and (max-width: 1861px) {
  html {
    font-size: 15px;
  }


  body section#about .container .text p {
    font-size: 1.5rem;
  }

  body footer .footer-container .contact-list li span {
    margin-top: 5px;
  }
}

@media screen and (max-width: 992px) {
  html {
    font-size: 14px;
  }


  #about .container {
    flex-direction: column;
    gap: 20px;
  }

  body section#about .container .text p:last-of-type {
    width: 100%;
  }

  body section#brief .bg-img {
    height: 200px;
    object-fit: cover;
  }

  body section#projects h3 {
    font-size: 2rem;
  }

  body footer .footer-container {
    flex-direction: column;
  }

  body .project-details .project-brief {
    width: 100%;
  }

  body .slick-slider {
    padding: 0;
  }


  body .project-images img {
    object-fit: cover;
  }

  body .project-box {
    width: 100%;
  }

}

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }


  section#partners {
    padding: 0;
  }

  section#partners .image {
    width: 192px;
    height: 128px;
    padding: 5px;
  }

  #map {
    padding: 0;
  }

  .container {
    width: 100%;
    padding: 0 20px;
  }

  header nav .container ul.nav-list {
    display: none;
  }

  body .menu-item {
    display: flex;
  }

  body section#info .info-container {
    margin-top: 10px;
    padding: 40px 0;
  }

  body section#info .info-container .info h3 {
    font-size: 1.5rem;
  }

  body section#main h3 {
    font-size: 2.5rem;
  }

  html[lang="en"] body section#main h3 {
    font-size: 2rem;
  }

  body section#info .info-container .info span {
    font-size: 1.8rem;
  }

  body section#info {
    padding: 0;

  }
  body .project-box .options a {
    width: 100px;
    height: 35px;
  }
  body .project-box .options a span {
    line-height: 42px;
  }

  body section#info .cutter {
    clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0% 100%, 0% 0%, 50% 40%);
    height: 40px;
  }

  body section#partners .cutter {
    clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0% 100%, 0% 0%, 50% 15%);
    height: 200px;
  }

  body .project-details .title {
    font-size: 1.4rem;
  }


  body .project-details .project-brief {
    font-size: 1.25rem;
    margin-top: 20px;
  }

  body .project-details .info-container {
    flex-direction:  column;
    gap: 20px;
  }

  body .project-details .info-container .info  {
    width: unset;
  }



  body .project-details .info-container .info h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  body .project-details .info-container .info span {
    font-size: 2.5rem;
  }

  body section {
    padding: 20px 0;
  }

  body footer .footer-container {
    width: 100%;
    padding: 0 20px;
  }

  body section#info .container .text p {
    width: 100%;
    text-align: justify;
  }


  section#about .container .info-container {
    width: 100%;
  }


  body section#info .container .text p {
    font-size: 1.45rem;
  }

  body section#brief .container > div p {
    font-size: 1.4rem;
    text-align: justify;
  }

  body section#brief {
    margin-bottom: 60px;
  }

  body section#brief .cutter {
    height: 50px;
  }

  body .project-images .project-info h3 {
    font-size: 3.5rem;
  }



body section#about .container .text h4 {
  font-size: 1.2rem;
}

  html[lang="en"] body section#about .container .text h4 {
    font-size: 1rem;
  }

  body section#about .container .text p {
    font-size: 1.2rem;
  }

  body section#about .container .text {
    gap: 10px;
  }

  body section#brief {
    padding-bottom: 50px;
  }
}







@media screen and (max-width: 470px) {
  html {
    font-size: 13px;
  }


  section#info .info-container .info {
    gap: 0px;
  }

  body footer .footer-container .contact-list li {
    position: relative;
    background-color: var(--third-color);
    color: var(--main-color);
    border: 1px solid var(--third-color);
    width: 160px;
    height: 25px;
    cursor: pointer;
  }

  body footer .footer-container .contact-list li span {
    font-size: 1.2rem;
    margin-top: 5px;
  }

  body footer .footer-rights {
    /* flex-direction: column; */
    gap: 10px;
  }

  body footer .footer-rights .icons a {
    width: 25px;
    height: 25px;
  }

  body footer .footer-rights .icons a i {
    font-size: 1rem;
  }

  body section#about .container .text h4 {
    font-size: 1.4rem;
  }


  body section#about .container .text p:last-of-type {
    font-size: 1.15rem;
  }

  body section#about .container .text p {
    font-size: 1.1rem;
  }

  body .modal-btn {
    padding: 5px 15px;
    font-size: 1.5rem;
  }

  body #partners .container h3 {
    font-size: 2.5rem;
  }


}


@media screen and (max-width: 1024px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}